import React from 'react';
import { Box } from '@latitude/box';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { SecondaryHero } from '@latitude/hero';
import { Metadata } from '@latitude/metadata';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';
import { Text } from '@latitude/text';
import { Heading4, Heading5, Heading6 } from '@latitude/heading';
import { SITE_URL } from '../../utils/constants';
import Layout from '../../components/layout';

const StyledBoxSection = styled(Box.Section)`
  max-width: 700px;
`;

export default ({ location }) => {
  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Metadata
          title="Disclosures | Gem by Latitude"
          description="Disclosures"
          canonical={`${SITE_URL}${location.pathname}`}
        />
        <SecondaryHero heading="Disclosures" />

        <StyledBoxSection backgroundColor={COLOR.GREY6}>
          <Heading4>IMPORTANT DISCLOSURE</Heading4>
          <Text>
            In accordance with the Credit Contracts and Consumer Finance Act
            2003, we’re required to make the following disclosure when we make a
            payment reminder, acknowledge receipt of a hardship application or
            provide notification of a hardship application being declined.
            Please note the following:
          </Text>
          <Heading5 marginTop={MARGIN.M32} marginBottom={MARGIN.M16}>
            Do you need free independent financial assistance?
          </Heading5>
          <Text>
            If you have concerns about your finances, you can access free and
            confidential advice from an independent service such as MoneyTalks.
            You can contact MoneyTalks on 0800 345 123, SMS 4029 or find further
            details at www.moneytalks.co.nz
          </Text>
          <Heading5 marginTop={MARGIN.M32} marginBottom={MARGIN.M16}>
            Do you need to make a complaint?
          </Heading5>
          <Text>
            If you are unhappy and have a complaint, we want to hear from you.
            <br />
            To make a complaint simply call us on{' '}
            <Link href="tel:0800003943" trackId="complaint-cards-call">
              0800 003 943
            </Link>{' '}
            (Cards) or{' '}
            <Link href="tel:0800551948" trackId="complaint-personal-loans-call">
              0800 551 948
            </Link>{' '}
            (Personal Loans) Monday to Friday, 8:00am – 6:30pm.
            <br />
            <br />
            If you are not happy with our response or handling of the complaint,
            you have access to a free, independent dispute resolution service
            called the Insurance & Financial Services Ombudsman Scheme (“IFSO”)
            who can assist to investigate or resolve the complaint. You can
            contact IFSO directly by visiting{' '}
            <Link href="https://www.ifso.nz" trackId="contact-ifso-web">
              ifso.nz
            </Link>
            , emailing{' '}
            <Link
              href="mailto:info@infso.nz"
              trackId="contact-ifso-email"
              trackEventData={{
                category: 'contact-external'
              }}
            >
              info@ifso.nz
            </Link>{' '}
            or calling{' '}
            <Link
              href="tel:0800888202"
              trackId="contact-ifso-call"
              trackEventData={{
                category: 'contact-external'
              }}
            >
              0800 888 202
            </Link>
            <br />
            <br />
            Alternatively, you can also refer the matter to the Commerce
            Commission:
            <br />
            <br />
            <strong>Commerce Commission</strong>
            <br />
            You can write to them at this address - PO Box 2351, Wellington
            6140, New Zealand, or call them on{' '}
            <Link
              href="tel:0800943600"
              trackId="commerce-commission-call"
              trackEventData={{
                category: 'contact-external'
              }}
            >
              0800 943 600
            </Link>{' '}
            or email{' '}
            <Link
              href="mailto:contact@comcom.govt.nz"
              trackId="commerce-commission-email"
              trackEventData={{
                category: 'contact-external'
              }}
            >
              contact@comcom.govt.nz
            </Link>
            <br />
            <br />
            Alternatively if you would want to discuss your account with us,
            please call
          </Text>
          <Heading6 marginTop={MARGIN.M32} marginBottom={MARGIN.M16}>
            Card
          </Heading6>
          <Text>
            {' '}
            <li>Call 0800 003 943, Mon to Fri: 08:00am – 06:30pm (NZST)</li>
          </Text>
          <Heading6 marginBottom={MARGIN.M16} marginTop={MARGIN.M16}>
            Personal Loans
          </Heading6>
          <Text>
            {' '}
            <li>Call 0800 551 948, Mon to Fri: 08:00am – 06:30pm (NZST)</li>
          </Text>
          <br />
          <Text>Credit is provided by Latitude Financial Services Limited</Text>
        </StyledBoxSection>
      </main>
    </Layout>
  );
};
